<template lang="pug">
carousel.new-products-carousel(
  :autoplay="true",
  :per-page-custom='[[320, 3],[480, 4],[768, 5],[992, 6],[1024, 6], [1600, 8]]',
  :navigation-enabled='true',
  :navigation-click-target-size='0',
  :navigation-next-label="'&\#8250'",
  :navigation-prev-label="'&\#x2039'",
  :min-swipe-distance='0',
  :pagination-padding='3',
  pagination-active-color="#ee8a22"
)
  slide(v-if="features.bodywork")
    i.product-detail-vehicle-icon(:class='features.bodywork.icon')
    .d-block
      span.badge.badge-pill.badge-light.badge-90(v-text="features.bodywork.name")
  slide(v-if="features.mileage")
    i.icon-road.product-detail-vehicle-icon
    .d-block
      span.badge.badge-pill.badge-light.badge-90 {{ features.mileage | formatNumber }}Km.
  slide(v-if="features.displacement")
    i.icon-engine.product-detail-vehicle-icon
    .d-block
      span.badge.badge-pill.badge-light.badge-90(v-text="features.displacement")
  slide(v-if="features.fuel")
    i.icon-gasoline.product-detail-vehicle-icon(v-if="features.fuel === 'bencina'")
    i.icon-petroleum.product-detail-vehicle-icon(v-if="features.fuel === 'diesel'")
    i.icon-electric.product-detail-vehicle-icon(v-if="features.fuel === 'electrico'")
    i.icon-gas.product-detail-vehicle-icon(v-if="features.fuel === 'gas'")
    i.icon-Hibrido.product-detail-vehicle-icon(v-if="features.fuel === 'hibrido'")
    .d-block
      span.badge.badge-pill.badge-light.badge-90.text-capitalize(v-text="features.fuel")
  slide(v-if="features.transmission")
    i.icon-automatic.product-detail-vehicle-icon(v-if="features.transmission === 'automático'")
    i.icon-mechanic.product-detail-vehicle-icon(v-if="features.transmission === 'manual'")
    .d-block
      span.badge.badge-pill.badge-light.badge-90.text-capitalize(v-text="features.transmission")
  slide(v-if="features.traction")
    span.product-detail-vehicle-icon.icon-awd(v-if="features.traction === 'awd'")
      span(class="path1")
      span(class="path2")
      span(class="path3")
      span(class="path4")
      span(class="path5")
    i.icon-x2.product-detail-vehicle-icon(v-if="features.traction === '4x2'")
    i.icon-x4.product-detail-vehicle-icon(v-if="features.traction === '4x4'")
    .d-block
      span.badge.badge-pill.badge-light.badge-90.text-capitalize(v-text="features.traction")
  slide(v-if="features.number_seat")
    i.icon-seat.product-detail-vehicle-icon
    .d-block
      span.badge.badge-pill.badge-light.badge-90(v-text="features.number_seat")
  slide(v-if="features.number_door")
    i.icon-doors.product-detail-vehicle-icon
    .d-block
      span.badge.badge-pill.badge-light.badge-90(v-text="features.number_door")
</template>
<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  name: "Features",
  components: {
    Carousel,
    Slide,
  },
  props: {
    features: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  filters: {
    formatNumber: (value) => {
      if (!value) return "";
      value = value.toString();
      value = value.replaceAll(/\./g, "");
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>
<style lang="scss">
.VueCarousel {
  padding-bottom: 0 !important;
  &-wrapper {
    min-height: 100px;
  }
  &-inner {
    min-height: 91px !important;
  }
  &-slide {
    text-align: center;
    &-active {
      &:hover {
        border: 0;
        box-shadow: none;
        transform: translate(0, 0);
      }
    }
  }
  &-navigation {
    &-button {
      font-size: 2rem;
      top: 36% !important;
    }
    &-prev {
      transform: translateY(-50%) translateX(-65%);
    }
    &-next {
      transform: translateY(-50%) translateX(65%);
    }
  }
  &-dot {
    &-container {
      margin-top: 0 !important;
    }
  }
}
.product-detail-vehicle-icon {
  display: block;
  height: 50px;
  &:before {
    vertical-align: middle;
  }
}
.icon {
  &-citycar,
  &-hatchback,
  &-coupe,
  &-sedan,
  &-convertible,
  &-station-wagon,
  &-suv,
  &-van,
  &-pickup-truck,
  &-truck,
  &-bus,
  &-machinery,
  &-motorcycle {
    font-size: 2.5rem;
  }
  &-doors,
  &-seat,
  &-engine,
  &-icon,
  &-road,
  &-automatic,
  &-mechanic,
  &-gasoline,
  &-petroleum,
  &-electric,
  &-gas,
  &-hibrido,
  &-awd,
  &-x2,
  &-x4 {
    font-size: 1.8rem;
    padding-top: 5px;
  }
}
</style>
