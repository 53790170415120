<template lang="pug">
  #video
    iframe.d-block.img-fluid.w-100.product-video(
      :src="urlFull"
      allow="accelerometer; autoplay; encrypted-media; gyroscope;"
      title="Video"
      frameborder="0"
      allowfullscreen
    )
</template>
<script>
export default {
  name:'VYoutube',
  data(){
    return {
      url:"https://www.youtube.com/embed/",
      urlFull:""
    }
  },
  props: {
    videoId:{
      type:String,
      default: 'ljTmkR4EnC8'
    },
    playerOption:{
      type:Object,
      default: function(){
        return {
          rel:0,
          start:1,
          autohide:1,
          showinfo:0,
          controls:0,
          autoplay:1,
          enablejsapi:1,
          mute:0,
          modestbranding:1
        }
      }
    },
  },
  created() {
    this.urlFull = `${this.url}${this.videoId}?${this.serialize(this.playerOption)}&noCachePlease=${this.time()}`
  },
  methods:{
    serialize: function(obj) {
      let str = [];
      for (let p in obj)
        if (obj.hasOwnProperty(p))  str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]))
      return str.join("&")
    },
    time: () => Math.floor(new Date().getTime() / 1000)
  }
}
</script>
<style lang="scss">
  .product-video{
    height: 56vw;
    @media (min-width: 768px) {
      height: 41.5vw;
    }
    @media (min-width: 992px) {
      height: 32vw;
    }
  }
</style>