<template lang="pug">
b-row
  b-col(cols="12" md="8" v-if="price").mb-2
    span.product-label-price Precio:
    span.product-price ${{ price | formatNumber }}
  b-col(cols="12" md="8" v-if="funded_price > 0")
    span.product-label-price Crédito
    span.product-price ${{ funded_price | formatNumber }}
</template>
<script>
export default {
  name:"Price",
  props:{
    price:{
      type:String,
      default:""
    },
    funded_price:{
      type:String,
      default:""
    }
  }
}
</script>
<style lang="scss">
  @import "@/assets/scss/_variables";

  .product-label-price {
    background-color: #000;
    border-radius: 20px 0 0 20px;
    color: white;
    display: inline-block;
    margin-right: 10px;
    margin-top: 10px;
    padding: 5px 20px 5px 25px;
    position: relative;
    &:after {
      border-left: 0px solid transparent;
      border-right: 11px solid transparent;
      border-top: 30px solid rgb(0, 0, 0);
      content:"";
      height: 0;
      position:absolute;
      right: -11px;
      top:0;
      width: 0; 
      z-index:0;
    }
  }
  .product-price {
    background-color: $primary;
    border-radius: 0px 30px 30px 0;
    color: white;
    display: inline-block;
    font-size: 1.2rem;
    font-weight: 600;
    height: 42px;
    margin-left: 7px;
    padding: 7px 20px 7px 15px;
    position:relative;
    top: 2px;
    &:before {
      border-bottom: 42px solid $primary;
      border-left: 15px solid transparent;
      border-right: 0px solid transparent;
      content:"";
      height: 0;
      left: -15px;
      position:absolute;
      top:0;
      width: 0;
      z-index:0;
    }
  }
</style>