<template lang="pug">
#accessories
    b-row.text-center.justify-content-center
        b-col(cols="12")
        #accesories.mt-2
            h5.my-4 Más accesorios adicionales
            .accessories
                .accessory(v-for="accessory in sortedAccessories")
                    svg-icon(:icon="accessory.icon").accessory-icon
                    .accessory-name(v-text="accessory.name")
</template>
<script>
import SvgIcon from "./SvgIcon";

export default {
    name: "Accesories",
    components: {
        SvgIcon,
    },
    props: {
        accessories: {
            type: Array,
            required: true,
            default: function () {
                return [];
            }
        },
    },
    computed: {
        sortedAccessories() {
            const copyAccessories = this.accessories.slice()
            return copyAccessories.sort((a, b) => a.name.localeCompare(b.name))
        }
    }
};
</script>
<style lang="scss">
@import "@/assets/scss/_variables";
#accessories {
    border-top: 1px solid #e5e5e5;
}
.accessories {
    column-gap: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 15px;
    margin-bottom: 30px;
    padding: 2em 1.5em;
    @media (min-width: 1600px) {
        grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.accessory {
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: center;
    &-icon {
        fill: $primary;
        stroke: transparent;
        height: 2.5em;
        width: 2.5em;
    }
    &-name {
        text-align: left;
        font-size: 0.75rem;
        line-height: 1;
    }
}
.VueCarousel {
    &-navigation {
        &-next {
            right: 15px !important;
        }
        &-prev {
            left: 15px !important;
        }
    }
}
</style>
