<template lang="pug">
b-row.actions-contact.justify-content-md-center.pb-3
  b-col(cols="12")
    h6(v-if="payment_type === 2") ¿Quieres comprarlo con crédito?
    h6(v-else) Contactate con el vendedor
  b-col(cols="12" md="5" v-if="payment_type === 2").mt-2
    v-credit
  b-col(cols="6" :md="payment_type === 2 ? '3' : '6'").mt-2
    v-whatsapp(:phone="phone" :message="message")
  b-col(cols="6" :md="payment_type === 2 ? '3' : '6'").mt-2
    v-call(:phone="phone")
</template>
<script>
import VWhatsapp from "../helpers/v-whatsapp";
import VCall from "../helpers/v-call";
import VCredit from "../helpers/v-credit";

export default {
  name: "Contact",
  props: {
    payment_type: {
      type: Number,
      default: 0,
    },
    phone: {
      type: Number,
      default: 0,
    },
    message: {
      type: String,
      default: "",
    },
  },
  components: {
    VWhatsapp,
    VCall,
    VCredit,
  },
};
</script>
<style lang="scss">
.actions-contact {
  text-align: center;
  margin-top: 10px;
  .col-6 {
    @media (max-width: 576px) {
      margin-top: 10px;
    }
  }
  @media (max-width: 1220px) {
    a,
    button {
      font-size: 0.9rem;
    }
  }
}
</style>
