<template lang="pug">
    div(v-html="require(`!html-loader!@/assets/img/accessories/${icon}`)")
</template>

<script>
export default {
  props: ['icon'],
  updated() {
    if (!this.$el || !this.$el.firstChild) {
      console.warn('Error en updated hook: `$el` o su primer hijo no están definidos')
      return
    }
    this.$el.firstChild.classList.add('fill-current')
  }
}
</script>