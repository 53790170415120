<template lang="pug">
#detail
  b-row
    b-col(cols="6" lg="4" v-if="vehicle.brand")
      span.text-muted Marca:
      span.badge.badge-pill.badge-light.ml-1(v-text="vehicle.brand.name")
    b-col(cols="6" lg="4" v-if="vehicle.modelo")
      span.text-muted Modelo:
      span.badge.badge-pill.badge-light.ml-1(v-text="vehicle.modelo.name")
    b-col(cols="12" lg="4" v-if="vehicle.version")
      span.text-muted Versión:
      span.badge.badge-pill.badge-light.ml-1(v-text="vehicle.version")
    b-col(cols="6" lg="4" v-if="vehicle.year")
      span.text-muted Año:
      span.badge.badge-pill.badge-light.ml-1(v-text="vehicle.year")
    b-col(cols="6" lg="4" v-if="vehicle.owners")
      span.text-muted Dueños:
      span.badge.badge-pill.badge-light.ml-1 {{ vehicle.owners }}
    b-col(cols="6" lg="4" v-if="vehicle.key_copies > 0")
      span.text-muted Llaves:
      span.badge.badge-pill.badge-light.ml-1 {{ vehicle.key_copies }}
    b-col(cols="6" lg="4")
      span.text-muted ID:
      span.badge.badge-pill.badge-light.ml-1 {{ id.toString().padStart(6, "0") }}
</template>
<script>
export default {
  name:"Detail",
  props:{
    id: {
      type:Number,
      default:0
    },
    vehicle:{
      type:Object,
      default: function() {
        return {}
      }
    }
  }
}
</script>
<style lang="scss">
#detail {
  border-top: 1px solid #e5e5e5;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-top: 15px;
  .text-muted {
    width: 55px;
    display: inline-block;
  }
}
</style>